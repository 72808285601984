import gql from 'graphql-tag';

export default gql`
  fragment CommonImage on Image {
    _id
    thumbnail
    small
    medium
    large
    original
  }
`;
